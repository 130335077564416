import React, { useState } from "react";
import LayoutWrapper from "../../component/LayoutWrapper/Index";
import CommunityTemplate from "../../component/CommunityTemplate";

const CommunityDetail = (props: any) => {
  const [siteTitle, setSiteTitle] = useState("");
  const [siteDesc, setSiteDesc] = useState("");
  const pageData = {
    id: "kensington-homes-for-sale",
    titleTag: "Kensington Homes for Sale | Real Estate | The Selby Team",
    descTag:
      "Search the newest listings to find the best homes for sale in Kensington, one of San Diego's oldest neighborhoods. Contact The Selby Team today!",
  };
  return (
    <LayoutWrapper
      title={pageData.titleTag}
      desc={pageData.descTag}
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <CommunityTemplate
        id={pageData.id}
        setSiteTitle={setSiteTitle}
        setSiteDesc={setSiteDesc}
        listingId="40642"
      />
    </LayoutWrapper>
  );
};

export default CommunityDetail;
